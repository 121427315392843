import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app.routing.module';

import { AppComponent } from './app.component';
import { NavBarComponent } from './navbar';
import { FooterComponent } from './footer';
import { LoaderComponent } from './loader';

import {
    AccountContainerComponent,
    AccountDataService,
    MakePaymentComponent,
    PaymentFailureComponent,
    PaymentMadeComponent,
    PaymentSuccessComponent,
    ValidateAccountComponent,
} from './account';

import {
    AuthInterceptor,
    LoaderService,
    RouterService,
    BankDataService,
    OrdinalPipe,
    NumbersOnlyDirective,
    ObfuscateOnBlurDirective
} from './_shared';


@NgModule({
    declarations: [

        // Components
        AccountContainerComponent,
        AppComponent,
        FooterComponent,
        LoaderComponent,
        MakePaymentComponent,
        NavBarComponent,
        PaymentFailureComponent,
        PaymentMadeComponent,
        PaymentSuccessComponent,
        ValidateAccountComponent,

        // Pipes
        OrdinalPipe,

        // Directives
        NumbersOnlyDirective,
        ObfuscateOnBlurDirective
    ],
    imports: [

        // Angular modules
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,

        // Custom modules
        AppRoutingModule
    ],
    providers: [
        AccountDataService,
        BankDataService,
        LoaderService,
        RouterService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
