import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {

    private loaderChanges: Subject<boolean> = new Subject<boolean>();

    getLoaderChanges(): Subject<boolean> {
        return this.loaderChanges;
    }

    set loading(loading: boolean) {
        this.loaderChanges.next(loading);
    }

}
