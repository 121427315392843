import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpBase } from '../_shared';
import {
    ValidateAccountRequest,
    ValidateAccountResponse,
    GetAccountResponse,
    MakePaymentRequest,
    MakePaymentResponse
} from '../_shared/models';

@Injectable()
export class AccountDataService extends HttpBase {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    validate(validateAccountRequest: ValidateAccountRequest): Observable<ValidateAccountResponse> {
        return this.postInternal('/account/validate', validateAccountRequest);
    }

    get(): Observable<GetAccountResponse> {
        return this.getInternal('/account');
    }

    makePayment(makePaymentRequest: MakePaymentRequest): Observable<MakePaymentResponse> {
        return this.postInternal('/account/payment', makePaymentRequest);
    }

}
