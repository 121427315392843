import { Component, OnInit } from '@angular/core';

import { AccountState, GetAccountResponse, MakePaymentResponse } from '../_shared/models';
import { RouterService, LoaderService } from '../_shared';
import { AccountDataService } from './account-data.service';

@Component({
    templateUrl: './account-container-component.html'
})
export class AccountContainerComponent implements OnInit {

    accountState = {
        Validate: AccountState.Validate,
        PaymentMade: AccountState.PaymentMade,
        MakePayment: AccountState.MakePayment,
        PaymentSuccess: AccountState.PaymentSuccess,
        PaymentFailed: AccountState.PaymentFailed
    };

    currentAccountState: AccountState = AccountState.Validate;
    getAccountResponse: GetAccountResponse;
    confirmationNumber = '';

    constructor(
        private accountDataService: AccountDataService,
        private loaderService: LoaderService,
        private routerService: RouterService
    ) { }

    ngOnInit(): void {
        this.routerService.getAccountStateChanges().subscribe(
            accountState => {
                this.handleAccountStateChange(accountState);
            }
        );
    }

    handleAccountStateChange(accountState: AccountState): void {
        if (accountState === AccountState.MakePayment) {
            this.loaderService.loading = true;
            this.accountDataService.get().subscribe(
                data => {
                    this.getAccountResponse = data;
                    this.currentAccountState = accountState;
                    this.loaderService.loading = false;
                }
            );
            return;
        }
        this.currentAccountState = accountState;
        this.loaderService.loading = false;
    }

    onMakePaymentResponse(makePaymentResponse: MakePaymentResponse): void {
        const confirmationNumber: string = makePaymentResponse.confirmationNumber;
        this.confirmationNumber = confirmationNumber;
    }

}
