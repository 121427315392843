import { Component, Input } from '@angular/core';

import { GetAccountResponse } from '../_shared/models';

@Component({
    selector: 'sms-payment-success',
    templateUrl: './payment-success.component.html'
})
export class PaymentSuccessComponent {
    @Input() amount: string;
    @Input() confirmationNumber: string;
}
