import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountContainerComponent } from './account';
import { RedirectGuard } from './guards/redirect.guard';

const routes: Routes = [
    { path: '', component: AccountContainerComponent, canActivate: [RedirectGuard] },
    { path: '**', component: AccountContainerComponent, canActivate: [RedirectGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
