import { Directive, ElementRef, HostListener  } from '@angular/core';

@Directive({
    selector: '[smsObfuscateOnBlur]'
})
export class ObfuscateOnBlurDirective {

    constructor(private el: ElementRef) { }

    @HostListener('focus') onfocus() {
        this.el.nativeElement.type = 'text';
    }

    @HostListener('blur') onblur() {
        this.el.nativeElement.type = 'password';
    }

}
