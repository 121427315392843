import { Component, OnInit } from '@angular/core';

import { fadeInOut, LoaderService } from '../_shared';

@Component({
    selector: 'sms-loader',
    templateUrl: './loader.component.html',
    animations: [fadeInOut()]
})
export class LoaderComponent implements OnInit {

    loading = false;

    constructor(private loaderService: LoaderService) { }

    ngOnInit(): void {
        this.loaderService.getLoaderChanges().subscribe(
            data => {
                this.loading = data;
            },
            err => {
                // Shouldn't happen, but do not lock the page if something goes wrong
                this.loading = false;
            }
        );
    }

}
