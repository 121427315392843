import { trigger, style, animate, transition } from '@angular/animations';

export function fadeInOut() {
    return trigger('fade-in-out', [
        transition('void => *', [
            style({opacity: 0}),
            animate('300ms ease-in', style({opacity: 1}))
        ]),
        transition('* => void', [
            animate('300ms ease-out', style({opacity: 0}))
        ]),
    ]);
}
