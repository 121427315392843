import { Component } from '@angular/core';

@Component({
    selector: 'sms-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavBarComponent {

    collapse = false;

    toggleCollapse() {
        this.collapse = !this.collapse;
    }

}
