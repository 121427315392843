import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { AccountState } from '../_shared/models';

@Injectable()
export class RouterService {

    private accountStateChanges: Subject<AccountState> = new Subject<AccountState>();

    scrollToTop(): void {
        document.body.scrollTop = 0;
    }

    getAccountStateChanges(): Subject<AccountState> {
        return this.accountStateChanges;
    }

    setAccountState(accountState: AccountState): void {
        this.accountStateChanges.next(accountState);
        this.scrollToTop();
    }

    navigateToValidate(): void {
        this.setAccountState(AccountState.Validate);
    }

    navigateToPaymentMade(): void {
        this.setAccountState(AccountState.PaymentMade);
    }

    navigateToMakePayment(): void {
        this.setAccountState(AccountState.MakePayment);
    }

    navigateToPaymentSuccess(): void {
        this.setAccountState(AccountState.PaymentSuccess);
    }

    navigateToPaymentFailed(): void {
        this.setAccountState(AccountState.PaymentFailed);
    }

}
