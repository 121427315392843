import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GetAccountResponse } from '../_shared/models';
import { LoaderService } from '../_shared';

@Component({
    selector: 'sms-payment-made',
    templateUrl: './payment-made.component.html'
})
export class PaymentMadeComponent implements OnInit {

    getAccountResponse: GetAccountResponse;

    constructor(private route: ActivatedRoute, private loaderService: LoaderService) { }

    ngOnInit() {
        this.getAccountResponse = this.route.snapshot.data['getAccountResponse'];
        this.loaderService.loading = false;
    }

}
