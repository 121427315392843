import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[smsNumbersOnly]'
})
export class NumbersOnlyDirective {

    constructor(private el: ElementRef) { }

    @HostListener('input') onInput() {
        const input = this.el.nativeElement.value;
        const cleanedInput = this.cleanNumberInput(input);
        this.el.nativeElement.value = cleanedInput;
    }

    cleanNumberInput(input: string): string {
        const regex = /[0-9]/;
        const charArray = input.split('');
        const filteredCharArray = charArray.filter(char => regex.test(char));
        const cleanedInput = filteredCharArray.join('');
        return cleanedInput;
    }

}
