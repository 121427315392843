import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { GetAccountResponse, MakePaymentRequest, MakePaymentResponse, GetBankResponse } from '../_shared/models';
import { BankDataService, LoaderService, RouterService } from '../_shared';
import { AccountDataService } from './account-data.service';

@Component({
    selector: 'sms-make-payment',
    templateUrl: './make-payment.component.html'
})
export class MakePaymentComponent implements AfterViewInit {

    @ViewChild('makePaymentForm') makePaymentForm;
    @ViewChild('routingNumber') routingNumber;
    @ViewChild('routingNumberInput') routingNumberInput;
    @Input() getAccountResponse: GetAccountResponse;
    @Output() makePaymentResponse: EventEmitter<MakePaymentResponse> = new EventEmitter<MakePaymentResponse>();
    makePaymentRequest: MakePaymentRequest = new MakePaymentRequest();
    makePaymentFormSubmitted = false;
    bankName = '';
    loadingBankName = false;
    paymentStage = 1;

    constructor(
        private bankDataService: BankDataService,
        private accountDataService: AccountDataService,
        private loaderService: LoaderService,
        private routerService: RouterService
    ) { }

    ngAfterViewInit(): void {
        fromEvent(this.routingNumberInput.nativeElement, 'input')
            .pipe(
                tap(() => {
                    this.bankName = '';
                }),
                debounceTime(1000)
            )
            .subscribe(
                data => {
                    if (!this.makePaymentRequest.routingNumber) {
                        return;
                    }
                    this.loadingBankName = true;
                    this.bankDataService.get(this.makePaymentRequest.routingNumber)
                        .subscribe(
                            (getBankResponse: GetBankResponse) => {
                                this.bankName = getBankResponse ? getBankResponse.name : '';
                                this.loadingBankName = false;
                            },
                            err => {
                                this.bankName = '';
                                this.loadingBankName = false;
                            }
                        );
                }
            );
    }

    getRedactedAccountNumber(accountNumber: string): string {
        return '************' + accountNumber.slice(-4);
    }

    submitMakePaymentForm(): void {
        this.makePaymentFormSubmitted = true;
        if (this.makePaymentForm.invalid || !this.bankName) {
            return;
        }
        this.paymentStage = 2;
    }

    cancelPayment() {
        this.paymentStage = 1;
        this.makePaymentFormSubmitted = false;
    }

    confirmPayment() {
        this.loaderService.loading = true;
        this.accountDataService.makePayment(this.makePaymentRequest)
            .subscribe(
                data => {
                    this.makePaymentResponse.emit(data);
                    this.loaderService.loading = false;
                    this.routerService.navigateToPaymentSuccess();
                },
                err => {
                    this.loaderService.loading = false;
                    this.routerService.navigateToPaymentFailed();
                }
            );
    }

}
