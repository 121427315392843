import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export abstract class HttpBase {

    constructor(private httpClient: HttpClient) { }

    protected getInternal<T1>(url: string): Observable<T1> {
        return this.httpClient
            .get(environment.baseUrl + url, { withCredentials: true })
            .pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    protected postInternal<T1>(url: string, body: any): Observable<T1> {
        return this.httpClient
            .post(environment.baseUrl + url, body, { withCredentials: true })
            .pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    protected putInternal<T1>(url: string, body: any): Observable<T1> {
        return this.httpClient
            .put(environment.baseUrl + url, body, { withCredentials: true })
            .pipe(catchError((err, caught) => this.handleError(err, caught)));
    }

    private handleError(err: any, caught: Observable<Object>): Observable<any> {
        return throwError(err);
    }
}
