import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpBase } from './http-base.service';
import { GetBankRequest, GetBankResponse } from './models';

@Injectable()
export class BankDataService extends HttpBase {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    get(routingNumber: string): Observable<GetBankResponse> {
        return this.getInternal(`/bank?routingNumber=${routingNumber}`);
    }

}
