import { Component, OnInit } from '@angular/core';

import { RouterService, LoaderService } from '../_shared';
import { AccountDataService } from './account-data.service';
import { ValidateAccountRequest, ValidateAccountResponse, AccountValidationError } from '../_shared/models';

@Component({
    selector: 'sms-validate-account',
    templateUrl: './validate-account.component.html'
})
export class ValidateAccountComponent implements OnInit {

    validateAccountRequest: ValidateAccountRequest = new ValidateAccountRequest();
    validationError = false;

    constructor(
        private routerService: RouterService,
        private accountDataService: AccountDataService,
        private loaderService: LoaderService
    ) { }

    ngOnInit(): void {
        localStorage.removeItem('accessTokenGuestPay');
    }

    submitValidateAccountForm(): void {
        this.validationError = false;
        this.loaderService.loading = true;
        this.accountDataService.validate(this.validateAccountRequest)
            .subscribe(
                data => {
                    if (data) {
                        this.handleValidateAccountResponse(data);
                    } else {
                        this.handleValidateAccountError();
                    }
                },
                err => {
                    this.handleValidateAccountError();
                },
            );
    }

    handleValidateAccountResponse(validateAccountResponse: ValidateAccountResponse) {
        localStorage.setItem('accessTokenGuestPay', validateAccountResponse.accessToken);
        switch (validateAccountResponse.accountValidationError) {
            case AccountValidationError.NoError:
                this.routerService.navigateToMakePayment();
                break;
            case AccountValidationError.PaymentMade:
                this.routerService.navigateToPaymentMade();
                break;
            default:
                this.handleValidateAccountError();
                break;
        }
    }

    handleValidateAccountError() {
        this.validationError = true;
        this.loaderService.loading = false;
    }
}
