import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean  {
      const environment = {
        'localhost:4200': 'https://dev-myaccount.newrez.com/',
        'd-appsvc-004-sms-guestpay.azurewebsites.net' : 'https://dev-myaccount.newrez.com/',
        'guestpay.test.newrez.com' : 'https://uat-myaccount.newrez.com/',
        'guestpay.newrez.com' : 'https://myaccount.newrez.com/'
      };
      window.location.href = environment[window.location.host];
    return false;
  }

}
